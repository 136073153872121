<template>
    <div class="market-grid-home">

        <div v-if="loading">
            <div class="text-center p-2">
                <div class="widget-loading"></div>
            </div>
        </div>
        <slot v-if="is_retail">
            <span v-for="(item, index) in static_items[retail_id]" :key="index"
                @click="getCurrentRetailTab(index, item.id)">
                <div class="market-grid-home-item">
                    <img :src="item.image">
                    <span> {{ item.label }} </span>
                    <p>{{ item.info }}</p>
                </div>
            </span>
        </slot>

        <div class="market-slider-tabs-box" v-if="is_branch">
            <router-link :to="{ name: 'RetailList', params: { retail_id: retail_id } }" class="mobile-back-btn">
                <i class="uil uil-angle-right-b"></i>
            </router-link>
            <div class="market-slider-tabs">
                <template>
                    <v-card>
                        <v-tabs v-model="tab" center-active>
                            <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
                            <v-tab v-for="(item, index) in static_items[retail_id]" :key="index"
                                @click="getCurrentRetailTab(index, item.id)">
                                <i :class="item.icon"></i>
                                <span>{{ item.label }}</span>
                            </v-tab>
                        </v-tabs>
                    </v-card>
                </template>
            </div>
        </div>
        <router-view></router-view>
    </div>
</template>
    
<script>

import static_items from "@/assets/retail_static_items";
import static_main from "@/assets/retail_static";
import vSelect2 from 'vue-select';
import moment from "moment-jalaali";
Vue.component('v-select2', vSelect2);


export default {
    name: 'RetailList',
    data: function () {
        return {
            static_items,
            static_main,
            tab: this.$store.state.currentTabMarket,
            loading: false,
            branch_list: [],
            is_retail: true,
            is_branch: true,
            retail_id: this.$route.params.retail_id,
        }
    },
    destroyed() {

    },
    beforeMount() {

    },
    mounted() {
        this.is_retail = Object.keys(this.static_items).includes(this.retail_id) && !this.$route.path.includes('subCategory');
        this.is_branch = Object.keys(this.static_items).includes(this.retail_id) && this.$route.path.includes('subCategory');
        var retail_id = this.$route.params.retail_id
        // برای ست کردن اسلایدر بالای صفحه که بازار درست انتخاب شود
        var index=0
        for (var element of static_main) {
            if (element.id==retail_id) {
                index=element.index
            }
        }
        this.$store.commit('setCurrentTabMarket', index); // از طریق ایندکس بازار درست انتخاب می شود
    },
    methods: {
        getCurrentRetailTab(tab, id) {
            this.$store.commit('setCurrentTabMarket', tab);
            this.$router.push({ name: "subCategoryList", params: { subcategory_id: id } });
        }
    },
    computed: {
    },
    watch: {
        $route(to, from) {
            this.retail_id = to.params.retail_id;
        }
    }
}
</script>
    